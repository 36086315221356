import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/diarcastro/clients/cure-foundation/looking-forward/src/templates/static-content-template.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ChapterTitle = makeShortcode("ChapterTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ChapterTitle chapter="Chapitre 1" title="ET APRÈS ?" subtitle="Avoir pleine conscience de son « nouveau soi »" mdxType="ChapterTitle" />
    <p>{`« Cancer survivors: Managing your emotions after cancer treatment. » Mayo Clinic, 2014, `}<a parentName="p" {...{
        "href": "https://www.mayoclinic.org/diseases-conditions/cancer/in-depth/cancer-survivor/art-20047129",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.mayoclinic.org/diseases-conditions/cancer/in-depth/cancer-survivor/art-20047129`}</a>{`. Consulté le 1er juillet 2016.`}</p>
    <p>{`« Emotional Side Effects.» American Cancer Society, n.d., `}<a parentName="p" {...{
        "href": "https://www.cancer.org/treatment/treatmentsandsideeffects/emotionalsideeffects/index",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.org/treatment/treatmentsandsideeffects/emotionalsideeffects/index`}</a>{`. Consulté le 1er juillet 2016.`}</p>
    <p>{`« Emotions After Cancer Treatment. » Livestrong Foundation, n.d., `}<a parentName="p" {...{
        "href": "https://www.livestrong.org/we-can-help/finishing-treatment/emotions-aftercancer-treatment",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.livestrong.org/we-can-help/finishing-treatment/emotions-aftercancer-treatment`}</a>{`. Consulté le 1er juillet 2016.`}</p>
    <p>{`« Facing Forward: Life After Cancer Treatment. » National Cancer Institute, 2014, `}<a parentName="p" {...{
        "href": "https://www.cancer.gov/publications/patient-education/facing-forward",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.gov/publications/patient-education/facing-forward`}</a>{`. Consulté le 22 février 2016.`}</p>
    <p>{`Magee, Sherri, et Kathy Scalzo. Picking Up the pieces: Moving Forward After Surviving Cancer. Vancouver: Raincoast Books, 2006.`}</p>
    <p>{`Proust, Marcel dans Magee, Sherri and Kathy Scalzo. Picking Up the Pieces: Moving Forward After Surviving Cancer. Vancouver: Raincoast Books, 2006, p. 40.`}</p>
    <p>{`« Your Emotions. » MacMillan Cancer Support, 2014, `}<a parentName="p" {...{
        "href": "https://www.macmillan.org.uk/information-and-support/coping/your-emotions/dealing-with-youremotions",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.macmillan.org.uk/information-and-support/coping/your-emotions/dealing-with-youremotions`}</a>{`. Consulté le 1er juillet 2016.`}</p>
    <p>{`« Vos émotions après un traitement du cancer. » Societé canadienne du cancer, n.d., `}<a parentName="p" {...{
        "href": "https://www.cancer.ca/fr-ca/cancer-information/cancer-journey/life-after-cancer/your-feelings-after-cancer/?region=qc",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/fr-ca/cancer-information/cancer-journey/life-after-cancer/your-feelings-after-cancer/?region=qc`}</a>{`. Consulté le 1er juillet 2016.`}</p>
    <ChapterTitle chapter="Chapitre 2" title="À QUOI S’ATTENDRE APRÈS LE TRAITEMENT" subtitle="La gestion des effets secondaires et leurs symptoms" mdxType="ChapterTitle" />
    <p>{`« Attention, Thinking, or Memory Problems. » American Society of Clinical Oncology, 2015, `}<a parentName="p" {...{
        "href": "https://www.cancer.net/navigating-cancer-care/side-effects/attention-thinking-or-memory-problems",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/navigating-cancer-care/side-effects/attention-thinking-or-memory-problems`}</a>{`. Consulté le 14 mars 2016.`}</p>
    <p>{`Bernstein, Lori J. « Cancer-Related Brain Fog: Information for Cancer Patients and Caregivers About Cancer-Related Brain Fog. » UHN, aout 2014, `}<a parentName="p" {...{
        "href": "https://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Cancer_Related_Brain_Fog.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Cancer_Related_Brain_Fog.pdf`}</a>{`. Consulté le 14 mars 2016.`}</p>
    <p>{`« Complementary Therapies. » Canadian Cancer Society, mai 2014, `}<a parentName="p" {...{
        "href": "https://www.cancer.ca/~/media/cancer.ca/CW/publications/Complementary%20therapies/Complementary-therapies-2016-EN.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/~/media/cancer.ca/CW/publications/Complementary%20therapies/Complementary-therapies-2016-EN.pdf`}</a>{`. Consulté le 30 octobre 2015.`}</p>
    <p>{`« Coping with Cancer Related Fatigue. » Leukemia and Lymphoma Society of Canada, 2009, `}<a parentName="p" {...{
        "href": "https://www.llscanada.org/sites/default/files/National/CANADA/Pdf/LLSC%20Coping%20with%20Cancer%20Related%20Fatigue%20Booklet%20-%20English.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.llscanada.org/sites/default/files/National/CANADA/Pdf/LLSC%20Coping%20with%20Cancer%20Related%20Fatigue%20Booklet%20-%20English.pdf`}</a>{`. Consulté le 14 mars 2016.`}</p>
    <p>{`« Coping with Chemo Brain. » Leukemia and Lymphoma Society of Canada, 2012, `}<a parentName="p" {...{
        "href": "https://www.llscanada.org/sites/default/files/National/CANADA/Pdf/LLSC%20Coping%20with%20Chemobrain%20Booklet%20-%20English.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.llscanada.org/sites/default/files/National/CANADA/Pdf/LLSC%20Coping%20with%20Chemobrain%20Booklet%20-%20English.pdf`}</a>{`. Consulté le 14 mars 2016.`}</p>
    <p>{`Drysdale, Elaine. « Chemo-Brain: Coping with Cognitive Change. » Forward, vol 2, no. 2, automne 2014. BC Cancer Agency, `}<a parentName="p" {...{
        "href": "https://www.digital.canadawide.com/i/389164-fall-2014",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.digital.canadawide.com/i/389164-fall-2014`}</a>{`. Consulté le 14 mars 2016.`}</p>
    <p>{`« Exercices de Kegel : Exercices de renforcement du plancher pelvien dans le traitement de l’incontinence urinaire. » Fondation d’aide aux personnes incontinentes, n.d., `}<a parentName="p" {...{
        "href": "https://www.canadiancontinence.ca/pdfs/pelvicmuscleexercises_fr.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.canadiancontinence.ca/pdfs/pelvicmuscleexercises_fr.pdf`}</a>{`. Consulté le 14 mars 2016.`}</p>
    <p>{`« Facing Forward: Life After Cancer Treatment. » National Cancer Institute, 2014, `}<a parentName="p" {...{
        "href": "https://www.cancer.gov/publications/patient-education/facing-forward",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.gov/publications/patient-education/facing-forward`}</a>{`. Consulté le 22 février 2016.`}</p>
    <p>{`« Gain de poids. » Société canadienne du cancer, n.d., `}<a parentName="p" {...{
        "href": "https://www.cancer.ca/frca/cancer-information/diagnosis-and-treatment/managing-side-effects/weight-gain/?region=qc",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/frca/cancer-information/diagnosis-and-treatment/managing-side-effects/weight-gain/?region=qc`}</a>{`. Consulté le 30 octobre 2015.`}</p>
    <p>{`Hammar, Mats, and Richard Lindgren. « Does physical exercise influence the frequency of postmenopausal hot flushes? » Acta obstetricia et gynecologica Scandinavica 69.5 (1990): 409-412.`}</p>
    <p>{`Howell, Doris, and ELLICSR Research Team. « Managing Bowel Problems after Cancer Treatment. » UHN, 2013, `}<a parentName="p" {...{
        "href": "https://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Bowel_Problems_after_Cancer_Treatment.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Bowel_Problems_after_Cancer_Treatment.pdf`}</a>{`. Consulté le 14 mars 2016.`}</p>
    <p>{`Howell, Doris, Rob Hamilton, and ELLICSR Research Team. « Managing Changes in Your Bladder Function after Cancer Treatment. » UHN, 2013, `}<a parentName="p" {...{
        "href": "https://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Changes_in_Bladder_Function_after_Cancer_Treatment.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Changes_in_Bladder_Function_after_Cancer_Treatment.pdf`}</a>{`. Consulté le 14 mars 2016.`}</p>
    <p>{`« How to Manage Your Fatigue. » Cancer Care Ontario, 2015, `}<a parentName="p" {...{
        "href": "https://www.cancercare.on.ca/common/pages/UserFile.aspx?fileId=348063",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.on.ca/common/pages/UserFile.aspx?fileId=348063`}</a>{`. Consulté le 15 mars 2016.`}</p>
    <p>{`Incekol, Diana, and Cindy Murray. « Managing the Side Effects of Chemotherapy. » UHN, 2013, `}<a parentName="p" {...{
        "href": "https://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_the_Side_Effects_of_Chemotherapy.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_the_Side_Effects_of_Chemotherapy.pdf`}</a>{`. Consulté le 14 mars 2016.`}</p>
    <p>{`« La vie après un traitement contre le cancer. » Société canadienne du cancer, mai 2013, `}<a parentName="p" {...{
        "href": "https://www.cancer.ca/~/media/cancer.ca/CW/publications/Life%20after%20cancer%20treatment/Life-After-Cancer-Treatment-Fr-May2016.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/~/media/cancer.ca/CW/publications/Life%20after%20cancer%20treatment/Life-After-Cancer-Treatment-Fr-May2016.pdf`}</a>{`. Consulté le 30 octobre 2015.`}</p>
    <p>{`« Long-Term Side Effects of Cancer Treatment. » American Society of Clinical Oncology, 2015, `}<a parentName="p" {...{
        "href": "https://www.cancer.net/survivorship/long-term-side-effectscancer-treatment",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/survivorship/long-term-side-effectscancer-treatment`}</a>{`. Consulté le 14 mars 2016.`}</p>
    <p>{`« Lymphedema. » BC Cancer Agency, 2014, `}<a parentName="p" {...{
        "href": "https://www.bccancer.bc.ca/health-info/types-of-cancer/breast-cancer/breast/lymphedema",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.bccancer.bc.ca/health-info/types-of-cancer/breast-cancer/breast/lymphedema`}</a>{`. Consulté le 15 mars 2016.`}</p>
    <p>{`Mayo Clinic Staff. « Cancer Fatigue: Why It Occurs and How to Cope. » Mayo Foundation for Medical Education and Research, 2014, `}<a parentName="p" {...{
        "href": "https://www.mayoclinic.org/diseases-conditions/cancer/in-depth/cancer-fatigue/art-20047709",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.mayoclinic.org/diseases-conditions/cancer/in-depth/cancer-fatigue/art-20047709`}</a>{`. Consulté le 15 mars 2016.`}</p>
    <p>{`« Menopausal Symptoms in Women. » American Society of Clinical Oncology, février 2016, `}<a parentName="p" {...{
        "href": "https://www.cancer.net/navigating-cancer-care/side-effects/menopausal-symptoms-women",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/navigating-cancer-care/side-effects/menopausal-symptoms-women`}</a>{`. Consulté le 3 juin 2016.`}</p>
    <p>{`Palmer, Heather. « Brain Fog and Cancer: How You Can Fight Back. » Maximum Capacity, n.d., `}<a parentName="p" {...{
        "href": "https://www.maximumcapacity.org/contact.html",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.maximumcapacity.org/contact.html`}</a>{`. Consulté le 14 mars 2016.`}</p>
    <p>{`« Sexuality for the woman with cancer. » American Cancer Society, 2013, `}<a parentName="p" {...{
        "href": "https://www.cancer.org/treatment/treatmentsandsideeffects/physicalsideeffects/sexualsideeffectsinwomen/sexualityforthewoman",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.org/treatment/treatmentsandsideeffects/physicalsideeffects/sexualsideeffectsinwomen/sexualityforthewoman`}</a>{`. Consulté le 27 juin 2016.`}</p>
    <p>{`« Soulager la douleur : Guide à l’intention des personnes atteintes de cancer. » Société canadienne du cancer, mai 2015, `}<a parentName="p" {...{
        "href": "https://www.cancer.ca/~/media/cancer.ca/CW/publications/Pain%20relief/Pain-relief_2016_EN.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/~/media/cancer.ca/CW/publications/Pain%20relief/Pain-relief_2016_EN.pdf`}</a>{`. Consulté le 28 mars 2016.`}</p>
    <p>{`« Types of Complementary Therapies. » American Society of Clinical Oncology, 2015, `}<a parentName="p" {...{
        "href": "https://www.cancer.net/navigating-cancer-care/how-cancertreated/integrative-medicine/types-complementary-therapies",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/navigating-cancer-care/how-cancertreated/integrative-medicine/types-complementary-therapies`}</a>{`. Consulté le 15 mars 2016.`}</p>
    <p>{`Warr, David, Doris Howell, and ELLICSR Research Staff. « Managing Neuropathy after Cancer Treatment. » UHN, 2013, `}<a parentName="p" {...{
        "href": "https://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Neuropathy_after_Cancer_Treatment.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Neuropathy_after_Cancer_Treatment.pdf`}</a>{`. Consulté le 14 mars 2016.`}</p>
    <p>{`« Weight Gain. » American Society of Clinical Oncology, 2015, `}<a parentName="p" {...{
        "href": "https://www.cancer.net/navigating-cancer-care/side-effects/weight-gain",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/navigating-cancer-care/side-effects/weight-gain`}</a>{`. Consulté le 27 juin 2016.`}</p>
    <p>{`« Weight Loss. » American Society of Clinical Oncology, 2015, `}<a parentName="p" {...{
        "href": "https://www.cancer.net/navigating-cancer-care/side-effects/weight-loss",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/navigating-cancer-care/side-effects/weight-loss`}</a>{`. Consulté le 14 mars 2016.`}</p>
    <p>{`« What Are Common “Late or Long-Term Effects” of Cancer Treatment? » Cancer Bridges, 2012, `}<a parentName="p" {...{
        "href": "https://www.cancerbridges.ca/2012/07/what-are-commonlate-or-long-term-effects-of-cancer-treatment",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancerbridges.ca/2012/07/what-are-commonlate-or-long-term-effects-of-cancer-treatment`}</a>{`. Consulté le 14 mars 2016.`}</p>
    <p>{`« What Are Complementary and Alternative Therapies? » MacMillan Cancer Support, 10 décembre 2014, `}<a parentName="p" {...{
        "href": "https://www.macmillan.org.uk/information-and-support/coping/complementary-therapies/complementary-therapies-explained/complementary-alternative-therapies.html",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.macmillan.org.uk/information-and-support/coping/complementary-therapies/complementary-therapies-explained/complementary-alternative-therapies.html`}</a>{`. Consulté le 14 mars 2016.`}</p>
    <p>{`« Your Body after Treatment. » Dana-Farber Cancer Institute, n.d., `}<a parentName="p" {...{
        "href": "https://www.dana-farber.org/For-Adult-Cancer-Survivors/Caring-For-Yourself-After-Cancer/Your-Body-After-Treatment.aspx#pain",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.dana-farber.org/For-Adult-Cancer-Survivors/Caring-For-Yourself-After-Cancer/Your-Body-After-Treatment.aspx#pain`}</a>{`. Consulté le 28 juin 2016.`}</p>
    <p>{`« Your sex life after vaginal cancer treatment. » Cancer Research UK, 29 septembre 2015, `}<a parentName="p" {...{
        "href": "https://www.cancerresearchuk.org/about-cancer/type/vaginal-cancer/living/your-sex-life-after-vaginal-cancer-treatment",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancerresearchuk.org/about-cancer/type/vaginal-cancer/living/your-sex-life-after-vaginal-cancer-treatment`}</a>{`. Consulté le 27 juin 2016.`}</p>
    <ChapterTitle chapter="Chapitre 3" title="ÉMOTIONS, PEURS ET RELATIONS" subtitle="Gérer sa santé émotionnelle" mdxType="ChapterTitle" />
    <p>{`« Anxiety. » Helpguide.org, n.d., `}<a parentName="p" {...{
        "href": "https://www.helpguide.org/home-pages/anxiety.htm",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.helpguide.org/home-pages/anxiety.htm`}</a>{`. Consulté le 20 juin 2016.`}</p>
    <p>{`Cantor, Robert C. And a time to live: Toward emotional well-being during the crisis of cancer. New York: Harper & Row, 1980.`}</p>
    <p>{`« Coping with Anger. » American Society of Clinical Oncology, January 2016, `}<a parentName="p" {...{
        "href": "https://www.cancer.net/coping-with-cancer/managing-emotions/coping-withanger",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/coping-with-cancer/managing-emotions/coping-withanger`}</a>{`. Consulté le 6 juin 2016.`}</p>
    <p>{`« Coping with Fear of Recurrence. » American Society of Clinical Oncology, August 2015, `}<a parentName="p" {...{
        "href": "https://www.cancer.net/survivorship/dealing-cancer-recurrence",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/survivorship/dealing-cancer-recurrence`}</a>{`. Consulté le 6 juin 2016.`}</p>
    <p>{`« Dealing with cancer recurrence. » American Society of Clinical Oncology, February 2016, `}<a parentName="p" {...{
        "href": "https://www.cancer.net/survivorship/dealing-cancer-recurrence",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/survivorship/dealing-cancer-recurrence`}</a>{`. Consulté le 6 juin 2016.`}</p>
    <p>{`« Facing Forward: Life After Cancer Treatment. » National Cancer Institute, 2014, `}<a parentName="p" {...{
        "href": "https://www.cancer.gov/publications/patient-education/facing-forward",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.gov/publications/patient-education/facing-forward`}</a>{`. Consulté le 22 février2016.`}</p>
    <p>{`Harpham, Wendy S. Diagnosis, cancer: Your guide to the first months of healthy survivorship. New York: W.W. Norton & Co, 2003.`}</p>
    <p>{`“Inquiètudes face à la réapparition possible du cancer. » Société canadienne du cancer, n.d., `}<a parentName="p" {...{
        "href": "https://www.cancer.ca/fr-ca/cancer-information/cancer-journey/life-after-cancer/worrying-that-the-cancer-will-comeback/?region=qc",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/fr-ca/cancer-information/cancer-journey/life-after-cancer/worrying-that-the-cancer-will-comeback/?region=qc`}</a>{`. Consulté le 1 juillet 2016.`}</p>
    <p>{`Holland, Jimmie. « Managing Uncertainty/Fear of Recurrence. » Memorial Sloan Kettering Cancer Center, n.d., `}<a parentName="p" {...{
        "href": "https://www.mskcc.org/experience/living-beyond-cancer/information-survivors/emotional-effects/managing-uncertainty-fear-recurrence",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.mskcc.org/experience/living-beyond-cancer/information-survivors/emotional-effects/managing-uncertainty-fear-recurrence`}</a>{`. Consulté le 3 juin 2016.`}</p>
    <p>{`Holland, Jimmie, and Sheldon Lewis. The human side of cancer: Living with Hope, Coping with Uncertainty. New York: Quill, 2001.`}</p>
    <p>{`Magee, Sherri, and Kathy Scalzo. Picking Up the pieces: Moving Forward After Surviving Cancer. Vancouver: Raincoast Books, 2006.`}</p>
    <p>{`Mayo Clinic Staff. « Cancer survivors: Managing your emotions after cancer treatment. » Mayo Foundation for Medical Education and Research, 8 Oct 2014, `}<a parentName="p" {...{
        "href": "https://www.mayoclinic.org/diseases-conditions/cancer/in-depth/cancer-survivor/ART-20047129",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.mayoclinic.org/diseases-conditions/cancer/in-depth/cancer-survivor/ART-20047129`}</a>{`. Consulté le 17 mai 2016.`}</p>
    <p>{`Mayo Clinic Staff. « What you can do to nurture relationships with friends and family. » Mayo Foundation for Medical Education and Research, 8 Oct 2014. `}<a parentName="p" {...{
        "href": "https://www.mayoclinic.org/diseases-conditions/cancer/in-depth/cancer-survivor/art-20045378?pg=2",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.mayoclinic.org/diseases-conditions/cancer/in-depth/cancer-survivor/art-20045378?pg=2`}</a>{`. Consulté le 30 juin 2016.`}</p>
    <p>{`Napolitano, Esther. « Six Tips for Managing Fear of Recurrence. » Memorial Sloan Kettering Cancer Center, 24 Mar 2014, `}<a parentName="p" {...{
        "href": "https://www.mskcc.org/blog/sixtips-managing-fear-recurrence",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.mskcc.org/blog/sixtips-managing-fear-recurrence`}</a>{`. Consulté le 3 juin 2016.`}</p>
    <p>{`Saulsman, L., Nathan, P., Lim, L., Correia, H., Anderson, R., and Campbell, B. « What? Me Worry!?! Mastering Your Worries. Module 9: Accepting Uncertainty.» Centre for Clinical Interventions, 2015, `}<a parentName="p" {...{
        "href": "https://www.cci.health.wa.gov.au/resources/infopax.cfm?Info_ID=46",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cci.health.wa.gov.au/resources/infopax.cfm?Info_ID=46`}</a>{`. Consulté le 22 mai 2016.`}</p>
    <p>{`Savard, Josée. Faire face au cancer : avec la pensée réaliste. Montréal: Flammarion Québec, 2010, pp.93-117.`}</p>
    <p>{`« What happens now? Follow up care for breast cancer patients. » Cancer Care Nova Scotia, 2016, `}<a parentName="p" {...{
        "href": "https://www.cancercare.ns.ca/en/home/patientsfamilies/survivorship/default.aspx",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.ns.ca/en/home/patientsfamilies/survivorship/default.aspx`}</a>{`. Consulté le 1 juillet 2016.`}</p>
    <p>{`« What happens now? Follow up care for colon cancer patients. » Cancer Care Nova Scotia, 2016, `}<a parentName="p" {...{
        "href": "https://www.cancercare.ns.ca/en/home/patientsfamilies/survivorship/default.aspx",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.ns.ca/en/home/patientsfamilies/survivorship/default.aspx`}</a>{`. Consulté le 1 juillet 2016.`}</p>
    <p>{`« What happens now? Follow up care for rectal cancer patients. » Cancer Care Nova Scotia, 2016, `}<a parentName="p" {...{
        "href": "https://www.cancercare.ns.ca/en/home/patientsfamilies/survivorship/default.aspx",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.ns.ca/en/home/patientsfamilies/survivorship/default.aspx`}</a>{`. Consulté le 1 juillet 2016.`}</p>
    <p>{`« What happens now? Follow up care for thyroid cancer patients. » Cancer Care Nova Scotia, 2016, `}<a parentName="p" {...{
        "href": "https://www.cancercare.ns.ca/en/home/patientsfamilies/survivorship/default.aspx",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.ns.ca/en/home/patientsfamilies/survivorship/default.aspx`}</a>{`. Consulté le 1 juillet 2016.`}</p>
    <p>{`« Your feelings after cancer treatment. » Macmillan Cancer Support, 31 July 2014 MacMillan Cancer Support, `}<a parentName="p" {...{
        "href": "https://www.macmillan.org.uk/informationand-support/coping/your-emotions/dealing-with-your-emotions/yourfeelings-after-cancer-treatment.html",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.macmillan.org.uk/informationand-support/coping/your-emotions/dealing-with-your-emotions/yourfeelings-after-cancer-treatment.html`}</a>{`. Consulté le 6 juin 2016.`}</p>
    <p>{`« Vos émotions après un traitement du cancer. » Societé canadienne du cancer, n.d., `}<a parentName="p" {...{
        "href": "https://www.cancer.ca/fr-ca/cancer-information/cancer-journey/life-after-cancer/your-feelings-after-cancer/?region=qc",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/fr-ca/cancer-information/cancer-journey/life-after-cancer/your-feelings-after-cancer/?region=qc`}</a>{`. Consulté le 1 juillet 2016.`}</p>
    <ChapterTitle chapter="Chapitre 4" title="SE RÉTABLIR PHYSIQUEMENT" subtitle="Se maintenir en santé" mdxType="ChapterTitle" />
    <p>{`« Cancer-Related Fatigue. » Leukemia and Lymphoma Society, 2015, `}<a parentName="p" {...{
        "href": "https://www.lls.org/treatment/managing-side-effects/cancer-related-fatigue",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.lls.org/treatment/managing-side-effects/cancer-related-fatigue`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`« Complementary Therapies: A guide for people with cancer. » Canadian Cancer Society, mai 2014, `}<a parentName="p" {...{
        "href": "https://www.cancer.ca/~/media/cancer.ca/CW/publications/Complementary%20therapies/Complementary-therapies-2014-EN.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/~/media/cancer.ca/CW/publications/Complementary%20therapies/Complementary-therapies-2014-EN.pdf`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`« Eating Well When You Have Cancer. » Canadian Cancer Society, mai 2014, `}<a parentName="p" {...{
        "href": "https://www.cancer.ca/~/media/cancer.ca/CW/publications/Eating%20well%20when%20you%20have%20cancer/Eating-well-when-you-have-cancer2014-EN.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/~/media/cancer.ca/CW/publications/Eating%20well%20when%20you%20have%20cancer/Eating-well-when-you-have-cancer2014-EN.pdf`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`« ELLICSR Blog. » ELLICSR: Health, Wellness & Cancer Survivorship Centre. n.d. `}<a parentName="p" {...{
        "href": "https://www.ellicsr.ca/en/connect_with_ELLICSR/ellicsr_blog",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.ellicsr.ca/en/connect_with_ELLICSR/ellicsr_blog`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`« ELLICSR Kitchen. » ELLICSR: Health, Wellness and Cancer Survivorship Centre, n.d., `}<a parentName="p" {...{
        "href": "https://www.ellicsr.ca/en/clinics_programs/ellicsr_kitchen",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.ellicsr.ca/en/clinics_programs/ellicsr_kitchen`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`Howell, Doris. « Managing Sleep Problems after Cancer. » UHN, mai 2013, `}<a parentName="p" {...{
        "href": "https://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Sleep_Problems_after_Cancer.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Sleep_Problems_after_Cancer.pdf`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`Howell, Doris, Christy Brissette, and ELLICSR Research Staff. « Managing Weight Gain after Cancer Treatment. » UHN, juin 2013, `}<a parentName="p" {...{
        "href": "https://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing`}</a>{`_ Weight_Gain_after_Cancer_Treatment.pdf. Consulté le 22 mars 2016.`}</p>
    <p>{`Howell, Doris, Christy Brissette, and ELLICSR Research Team. « Managing Weight Loss after Cancer Treatment. » UHN, juin 2013, `}<a parentName="p" {...{
        "href": "https://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Weight_Loss_after_Cancer_Treatment.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Weight_Loss_after_Cancer_Treatment.pdf`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`« La vie après un traitement contre le cancer. » Société canadienne du cancer, mai 2013, `}<a parentName="p" {...{
        "href": "https://www.cancer.ca/~/media/cancer.ca/CW/publications/Life%20after%20cancer%20treatment/Life-After-Cancer-Treatment-Fr-May2016.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/~/media/cancer.ca/CW/publications/Life%20after%20cancer%20treatment/Life-After-Cancer-Treatment-Fr-May2016.pdf`}</a>{`. Consulté le 30 octobre 2015. 31`}</p>
    <p>{`« Lifestyle Changes That Make a Difference: Nutrition and Physical Activity Guidelines for Cancer Survivors. » American Cancer Society, 2012, `}<a parentName="p" {...{
        "href": "https://www.cancer.org/acs/groups/content/@editorial/documents/document/acspc-037186.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.org/acs/groups/content/@editorial/documents/document/acspc-037186.pdf`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`« Living Well: Walk Your Way to Better Health. » Forward, vol. 2, no. 2, automne 2014, pp.6-7. BC Cancer Agency, `}<a parentName="p" {...{
        "href": "https://digital.canadawide.com/i/389164-fall-2014",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`digital.canadawide.com/i/389164-fall-2014`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`« Measuring Physical Activity Intensity. » Centers for Disease Control and Prevention. juin 2015, `}<a parentName="p" {...{
        "href": "https://www.cdc.gov/physicalactivity/basics/measuring/index.html",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cdc.gov/physicalactivity/basics/measuring/index.html`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`« Nutrition and Physical Activity During and after Cancer Treatment: Answers to Common Questions. » The American Cancer Society, 4 novembre 2013, `}<a parentName="p" {...{
        "href": "https://www.cancer.org/treatment/survivorshipduringandaftertreatment/nutritionforpeoplewithcancer/nutrition-and-physical-activity-during-and-after-cancer-treatment-answers-to-common-questions",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.org/treatment/survivorshipduringandaftertreatment/nutritionforpeoplewithcancer/nutrition-and-physical-activity-during-and-after-cancer-treatment-answers-to-common-questions`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`« Nutrition and Physical Activity Guidelines for Cancer Prevention. » American Cancer Society, janvier 2016, `}<a parentName="p" {...{
        "href": "https://www.cancer.org/acs/groups/cid/documents/webcontent/002577-pdf.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.org/acs/groups/cid/documents/webcontent/002577-pdf.pdf`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`« On-the-Go Guides. » Cancer and Careers, 2016, `}<a parentName="p" {...{
        "href": "https://www.cancerandcareers.org/publication_orders/new",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancerandcareers.org/publication_orders/new`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`« Our Cancer Prevention Recommendations. » World Cancer Research Fund International, n.d., `}<a parentName="p" {...{
        "href": "https://www.wcrf.org/int/research-we-fund/our-cancer-prevention-recommendations",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.wcrf.org/int/research-we-fund/our-cancer-prevention-recommendations`}</a>{`. Consulté le 22 mars 2016. Phan, Stephanie, Aronela Benea, and Linda Durkee.`}</p>
    <p>{`« Reclaim Your Energy: Coping with Cancer-Related Fatigue. » UHN, mai 2009, `}<a parentName="p" {...{
        "href": "https://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Reclaim_Your_Energy_Coping_with_Cancer_Fatigue.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Reclaim_Your_Energy_Coping_with_Cancer_Fatigue.pdf`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`« Physical Activity and Cancer. » American Cancer Society, 2007, `}<a parentName="p" {...{
        "href": "https://www.cancer.org/acs/groups/content/@nho/documents/document/physicalactivityandcancerpdf.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.org/acs/groups/content/@nho/documents/document/physicalactivityandcancerpdf.pdf`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`The Weight Watchers Research Department. « Exercise Intensity Levels: How to measure exercise intensity. » Weight Watchers International Inc., 2016, `}<a parentName="p" {...{
        "href": "https://www.weightwatchers.com/util/art/index_art.aspx?tabnum=1&art_id=20971",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.weightwatchers.com/util/art/index_art.aspx?tabnum=1&art_id=20971`}</a>{`. Consulté le 22 mars 2016. 32`}</p>
    <p>{`« What Happens Now?: Follow up Care for Breast Cancer Patients. » Cancer Care Nova Scotia, 2014, `}<a parentName="p" {...{
        "href": "https://www.cancercare.ns.ca/site-cc/media/cancercare/Follow%20Up%20Care%20for%20Breast%20Cancer%20Patients%281%29.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.ns.ca/site-cc/media/cancercare/Follow%20Up%20Care%20for%20Breast%20Cancer%20Patients%281%29.pdf`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`« What Happens Now?: Follow up Care for Colon Cancer Patients. » Cancer Care Nova Scotia, 2014, `}<a parentName="p" {...{
        "href": "https://www.cancercare.ns.ca/site-cc/media/cancercare/jun15Follow%20Up%20Care%20for%20Colon%20Cancer%20Patients%281%29.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.ns.ca/site-cc/media/cancercare/jun15Follow%20Up%20Care%20for%20Colon%20Cancer%20Patients%281%29.pdf`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`« What Happens Now?: Follow up Care for Rectal Patients. » Cancer Care Nova Scotia, 2014, `}<a parentName="p" {...{
        "href": "https://www.cancercare.ns.ca/sitecc/media/cancercare/Follow%20Up%20Care%20for%20Rectal%20Cancer%20Patients(1).pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.ns.ca/sitecc/media/cancercare/Follow%20Up%20Care%20for%20Rectal%20Cancer%20Patients(1).pdf`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <p>{`« What Happens Now?: Follow up Care for Thyroid Patients. » Cancer Care Nova Scotia. 2014. `}<a parentName="p" {...{
        "href": "https://www.cancercare.ns.ca/site-cc/media/cancercare/Follow%20Up%20Care%20for%20Thyroid%20Cancer%20Patients(1).pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.ns.ca/site-cc/media/cancercare/Follow%20Up%20Care%20for%20Thyroid%20Cancer%20Patients(1).pdf`}</a>{`. Consulté le 22 mars 2016.`}</p>
    <ChapterTitle chapter="Chapitre 5" title="RETOUR AU TRAVAIL" subtitle="Planifier son retour" mdxType="ChapterTitle" />
    <p>{`« Admissibilité à la rente d’invalidité. » Retraite Québec, 2015, `}<a parentName="p" {...{
        "href": "https://www.rrq.gouv.qc.ca/fr/programmes/regime_rentes/prestations_invalidite/Pages/admissibilite_ri.aspx",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.rrq.gouv.qc.ca/fr/programmes/regime_rentes/prestations_invalidite/Pages/admissibilite_ri.aspx`}</a>{`. Consulté le 6 janvier 2016.`}</p>
    <p>{`« Back to Work after Cancer. » Cancer and Careers, 2013, `}<a parentName="p" {...{
        "href": "https://www.cancerandcareers.org/en/at-work/back-to-work-after-cancer/back-to-work",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancerandcareers.org/en/at-work/back-to-work-after-cancer/back-to-work`}</a>{`. Consulté le 9 octobre 2015.`}</p>
    <p>{`« Cancer and Work. » Canadian Cancer Society, 25 février 2016, `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=vAtkO9obD-g",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.youtube.com/watch?v=vAtkO9obD-g`}</a>{`. Consulté le 1 mars 2016.`}</p>
    <p>{`« Cancer and Workplace Discrimination. » American Society of Clinical Oncology, juillet 2015, `}<a parentName="p" {...{
        "href": "https://www.cancer.net/survivorship/life-after-cancer/cancer-and-workplace-discrimination",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/survivorship/life-after-cancer/cancer-and-workplace-discrimination`}</a>{`. Consulté le 9 octobre 2015.`}</p>
    <p>{`« Facing Forward: Life After Cancer Treatment. » National Cancer Institute, 2014, `}<a parentName="p" {...{
        "href": "https://www.cancer.gov/publications/patient-education/facing-forward",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.gov/publications/patient-education/facing-forward`}</a>{`. Consulté le 22 février 22 2016.`}</p>
    <p>{`« Finding a Job after a Cancer. » American Society of Clinical Oncology, juillet 2015, `}<a parentName="p" {...{
        "href": "https://www.cancer.net/survivorship/life-after-cancer/finding-job-after-cancer",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/survivorship/life-after-cancer/finding-job-after-cancer`}</a>{`. Consulté le 9 octobre 2015.`}</p>
    <p>{`« Going Back to Work after Cancer. » American Society of Clinical Oncology, juillet 2014, `}<a parentName="p" {...{
        "href": "https://www.cancer.net/survivorship/life-after-cancer/going-backwork-after-cancer",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/survivorship/life-after-cancer/going-backwork-after-cancer`}</a>{`. Consulté le 9 octobre 2015.`}</p>
    <p>{`« Job Search Tools. » Cancer and Careers, 2013, `}<a parentName="p" {...{
        "href": "https://www.cancerandcareers.org/en/looking-for-work/exploring-your-options/job-search-tools",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancerandcareers.org/en/looking-for-work/exploring-your-options/job-search-tools`}</a>{`. Consulté le 9 octobre 2015.`}</p>
    <p>{`« La vie après un traitement contre le cancer. » Société canadienne du cancer, mai 2013, `}<a parentName="p" {...{
        "href": "https://www.cancer.ca/~/media/cancer.ca/CW/publications/Life%20after%20cancer%20treatment/Life-After-Cancer-Treatment-FrMay2016.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/~/media/cancer.ca/CW/publications/Life%20after%20cancer%20treatment/Life-After-Cancer-Treatment-FrMay2016.pdf`}</a>{`. Consulté le 30 octobre 2015.`}</p>
    <p>{`Lambert, Sylvie. « Coping Together: A Practical Guide for Couples Facing Cancer. » Centre de Recherche de St. Mary. Guidebook for Family Caregivers. Montréal : Fondation de l’Hôpital St. Mary, 2013.`}</p>
    <p>{`Parkinson, Maureen. Cancer and Returning to Work: A Practical Guide for Cancer Patients. 2ème éd. Vancouver: BC Cancer Agency, 2015, `}<a parentName="p" {...{
        "href": "https://www.bccancer.bc.ca/coping-and-support-site/Documents/Cancer_Returning%20to_Work_Manual_Interactive%20(3).pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.bccancer.bc.ca/coping-and-support-site/Documents/Cancer_Returning%20to_Work_Manual_Interactive%20(3).pdf`}</a>{`. Consulté le 9 octobre 2015.`}</p>
    <p>{`« Retour au travail après un cancer. » Fondation québécoise du cancer, n.d., `}<a parentName="p" {...{
        "href": "https://www.fqc.qc.ca/fr/information/apres-cancer/retour-au-travail-2",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.fqc.qc.ca/fr/information/apres-cancer/retour-au-travail-2`}</a>{`. Consulté le 6 janvier 2016.`}</p>
    <p>{`Samson, Mélanie. « Planifier La Transition Après Un Diagnostic De Cancer. » Canadian Cancer Society, May 2014, `}<a parentName="p" {...{
        "href": "https://www.aqsp.org/wp-content/uploads/2014/05/Congr%C3%A8s-2014-B5-M%C3%A9lanie-Samson_annexes.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.aqsp.org/wp-content/uploads/2014/05/Congr%C3%A8s-2014-B5-M%C3%A9lanie-Samson_annexes.pdf`}</a>{`. Consulté le 1 novembre 2015.`}</p>
    <p>{`« Services publics d’orientation. » Ordre des conseillers et conseillères d’orientation du Québec, n.d., `}<a parentName="p" {...{
        "href": "https://www.orientation.qc.ca/services-publics-dorientation",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.orientation.qc.ca/services-publics-dorientation`}</a>{`. Consulté le 7 décembre 2015.`}</p>
    <p>{`« Stopping Work after Cancer Treatment. » American Society of Clinical Oncology, n.d., `}<a parentName="p" {...{
        "href": "https://www.cancer.net/survivorship/life-after-cancer/stopping-work-after-cancer-treatment",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/survivorship/life-after-cancer/stopping-work-after-cancer-treatment`}</a>{`. Consulté le 9 octobre 2015.`}</p>
    <p>{`« Work and Finances. » Canadian Cancer Society, n.d., `}<a parentName="p" {...{
        "href": "https://www.cancer.ca/en/cancer-information/cancer-journey/life-after-cancer/work-and-finances/?region=qc",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/en/cancer-information/cancer-journey/life-after-cancer/work-and-finances/?region=qc`}</a>{`. Consulté le 20 octobre 2015.`}</p>
    <ChapterTitle chapter="Chapitre 6" title="LE SOUTIEN DES PROCHES AIDANTS" subtitle="Prendre soin du proche aidant" mdxType="ChapterTitle" />
    <p>{`Becker, Saul. « Young Carers. » The Blackwell Encyclopaedia of Social Work. Ed. M. Davies. Vancouver: Blackwell Publishers Ltd., 2000. 378.`}</p>
    <p>{`« Facing Forward : When Someone You Love Has Completed Cancer Treatment. » National Cancer Institute, 2014, `}<a parentName="p" {...{
        "href": "https://www.cancer.gov/Publications/patient-education/someone-you-love-completed-cancertreatment",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.gov/Publications/patient-education/someone-you-love-completed-cancertreatment`}</a>{`. Consulté le 21 février 2016.`}</p>
    <p>{`« How to Make Friends : Tips on Meeting People and Building Strong Friendships. » Helpguide.org, n.d., `}<a parentName="p" {...{
        "href": "https://www.helpguide.org/articles/relationships/how-to-make-friends.htm#how",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.helpguide.org/articles/relationships/how-to-make-friends.htm#how`}</a>{`. Consulté le 21 février 2016.`}</p>
    <p>{`Lambert, Sylvie. « Coping Together: A Practical Guide for Couples Facing Cancer. » Centre de Recherche de St. Mary. Guidebook for Family Caregivers. Montréal : Fondation de l’Hôpital St. Mary, 2013.`}</p>
    <ChapterTitle chapter="Chapitre 7" title="OÙ TROUVER DE L’INFORMATION FIABLE" subtitle="Comment rechercher et évaluer l'information" mdxType="ChapterTitle" />
    <p>{`« Cancer Information on the Internet. » American Cancer Society, 1 avril 2014, `}<a parentName="p" {...{
        "href": "https://www.cancer.org/cancer/cancerbasics/cancerinformation-on-the-internet",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.org/cancer/cancerbasics/cancerinformation-on-the-internet`}</a>{`. Consulté le 22 juin 2016.`}</p>
    <p>{`« Evaluating Internet Resources. » Georgetown University Library, n.d., `}<a parentName="p" {...{
        "href": "https://www.library.georgetown.edu/tutorials/research-guides/evaluatinginternet-content",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.library.georgetown.edu/tutorials/research-guides/evaluatinginternet-content`}</a>{`. Consulté le 22 juin 2016.`}</p>
    <p>{`« Evaluating Internet Sources. » University of Illinois at Urbana-Champaign University Library, n.d., `}<a parentName="p" {...{
        "href": "https://www.library.illinois.edu/ugl/howdoi/webeval.html",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.library.illinois.edu/ugl/howdoi/webeval.html`}</a>{`. Consulté le 2 février 2016.`}</p>
    <p>{`« How to Evaluate Health Information. » McGill University Health Center Libraries, n.d., `}<a parentName="p" {...{
        "href": "https://www.muhclibraries.ca/patients/how-to-evaluate-healthinformation/",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.muhclibraries.ca/patients/how-to-evaluate-healthinformation`}</a>{`. Consulté le 16 juillet 2016.`}</p>
    <p>{`Mayo Clinic Staff. Mayo Clinic Book of Alternative Medicine. 2ème éd. Birmingham, AL: Oxmoor House, 2010. 59.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      